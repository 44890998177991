import React from "react";

function HomePageText({ title, content }) {
  return (
    <>
      <div className="w-full flex flex-col px-6 pt-6 md:pt-16 md:px-32 lg:px-80 mb-16">
        <div className="text-center text-2xl font-bold mt-2 text-[#c8c3bc]">{title}</div>
        <div className="text-center text-xl font-bold mt-2 text-[#c8c3bc]">{content}</div>
      </div>
    </>
  );
}
export default HomePageText;
