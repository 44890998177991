import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Paragraph from "../components/Paragraph";
import Footer from "../components/Footer";

function Vizyon() {
  useEffect(() => {
    document.title = "Vizyon - Now Yapi";
  }, []);
  return (
    <>
      <Navbar />
      <Paragraph
        title="Now Yapı Vizyon"
        content="<p class='font-bold'>Geleceği İnşa Etmek</p>
        <br/>
        <p>Now Yapı İnşaat Şirketi olarak, vizyonumuz, inşaat sektöründe sadece binalar değil, aynı zamanda yaşam tarzını, çevreyi ve toplumları da şekillendiren bir lider olmaktır. İnsanların ihtiyaçlarına odaklanarak, kalite, güvenilirlik ve sürdürülebilirlik ilkelerine bağlı kalarak, mükemmelliği hedefliyoruz.</p>
        <br/>
        <p class='font-bold'>İnovasyon ve Teknoloji:</p>
        <p>Geleceği belirleyen temel unsur olan teknolojiye olan bağlılığımızla, inovasyonu sürekli bir şekilde benimseyerek sektörde öncü olmayı amaçlıyoruz. Akıllı bina teknolojilerinden sürdürülebilir enerji çözümlerine kadar geniş bir yelpazede teknolojik gelişmeleri takip ediyor ve uyguluyoruz.</p>
        <br/>
        <p class='font-bold'>Çevresel Sorumluluk:</p>
        <p>Doğaya ve çevreye karşı sorumluluğumuzun bilincindeyiz. Sürdürülebilir inşaat malzemeleri kullanarak, enerji verimliliğini artırarak ve yeşil alanları destekleyerek çevresel etkimizi minimize etmeyi taahhüt ediyoruz.</p>
        <br/>
        <p class='font-bold'>Müşteri Memnuniyeti:</p>
        <p>Müşteri memnuniyetini en üst düzeyde tutarak, projelerimizde estetik, fonksiyonellik ve konforu bir araya getiriyoruz. Müşterilerimizin beklentilerini aşmak, uzun vadeli güvenilir ilişkiler kurmak için önceliğimizdir.</p>
        <br/>
        <p class='font-bold'>Toplumsal Katkı:</p>
        <p>Toplumumuzun bir parçası olarak, şirketimizin başarısının toplumumuzun başarısı olduğunu biliyoruz. Eğitim, kültür, ve sosyal projelere destek vererek, toplumsal kalkınmaya katkı sağlamak için çaba gösteriyoruz.</p>
        <br/>
        <p class='font-bold'>Personel Gelişimi:</p>
        <p>Şirketimizin en değerli varlığı olan personelimizin sürekli gelişimini teşvik ediyoruz. Ekip üyelerimizin profesyonel ve kişisel becerilerini artırmalarını destekleyerek, Now Yapı ailesinin bir parçası olmaktan gurur duyuyoruz.</p>
        <br/>
        <p>Now Yapı İnşaat Şirketi olarak, yüksek standartlarda kalite, güvenilirlik ve sürdürülebilirlik ilkesini benimseyerek, inşaat sektöründe iz bırakan bir marka olmayı hedefliyoruz. Geleceği birlikte inşa etmek ve daha yaşanabilir bir dünya yaratmak için çalışıyoruz.</p>"
      />
      <Footer fixed={false} />
    </>
  );
}
export default Vizyon;
