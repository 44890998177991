import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imagesData from "../data/images.json";

function ProjectCard({ project }) {
  const [firstImage, setFirstImage] = useState("");

  useEffect(() => {
    const projectData = imagesData.find(
      (data) => data.projectTitle === project.title
    );

    if (
      projectData &&
      projectData.imagePaths &&
      projectData.imagePaths.length > 0
    ) {
      setFirstImage(projectData.imagePaths[0]);
    }
  }, [project]);

  return (
    <div className="h-auto my-4 w-full">
      <Link
        to={`/project/${project.id}`}
        key={project.id}
        className="bg-[#202325] p-6 shadow-md flex flex-row gap-4 w-full hover:shadow-xl hover:scale-110 transition-all transform duration-500"
      >
        <div className="w-1/6 flex flex-col justify-center">
          <h3 className="text-2xl text-[#c8c3bc] font-bold mb-2 vertical-text ">
            &#8226; {project.title}
          </h3>
        </div>
        <div className="w-5/6 flex flex-wrap flex-col lg:flex-row">
          <div className="w-1/2 order-2 lg:order-1">
            <p className="text-[#c8c3bc] mb-4">{project.description}</p>
          </div>
          <div className="w-full my-4 lg:my-0 lg:w-1/2 flex items-center order-1 lg:order-2">
            {firstImage && (
              <img
                src={`../images/${project.title}/${firstImage}`}
                alt={project.title}
                className="object-fill w-full h-[16rem] transition duration-300 transform hover:brightness-75 hover:cursor-pointer"
              />
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProjectCard;
