import React from "react";
import ProjectCard from "./ProjectCard";

function ProjectList({ projects, title }) {
  return (
    <div className="px-6 pt-6 md:pt-16 md:px-32 lg:px-80 mb-16">
      <div className="text-[#c8c3bc] font-bold text-5xl mb-4">
        <div>{title}</div>
      </div>
      <div className="border-t-4 border-gray-300 mb-6"></div>
      <div className="flex flex-wrap">
        {projects.map((project,index) => (
          <ProjectCard project={project} key={index}/>
        ))}
      </div>
    </div>
  );
}

export default ProjectList;
