import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import carouselImagesData from '../data/carousel.json';

const carouselImages = carouselImagesData.carouselImages;

function MyCarousel() {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      loop={true}
      
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {carouselImages.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image} alt={`Slide ${index + 1}`} className="object-fill w-full h-96 md:h-[32rem]" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default MyCarousel;
