import React, { useState, useEffect } from "react";
import GridCarousel from "../components/GridCarousel";
import imagesData from "../data/images.json";

function ProjectDetail({ project }) {
  const [firstImage, setFirstImage] = useState("");

  useEffect(() => {
    const projectData = imagesData.find(
      (data) => data.projectTitle === project.title
    );

    if (
      projectData &&
      projectData.imagePaths &&
      projectData.imagePaths.length > 0
    ) {
      setFirstImage(projectData.imagePaths[0]);
    }

    document.title = `${project.title} - Now Yapi`;
  }, [project]);

  return (
    <div>
      <div className="flex px-6 pt-6 md:pt-16 md:px-32 lg:px-80 text-[#c8c3bc] h-[32rem] border-b-2 border-gray-600 pb-4">
        <div className="flex-shrink-0 w-1/2 flex flex-col justify-center items-start p-4">
          <h2 className="text-2xl font-bold mb-4">{project.title}</h2>
          <p className="text-base mb-2">{project.description}</p>
          <p className="text-sm">
            Durum: {project.finishStatus ? "Tamamlandı" : "Devam Ediyor"}
          </p>
        </div>

        <div className="flex-shrink-0 w-1/2">
          <img
            src={`../images/${project.title}/${firstImage}`}
            alt={project.title}
            className="w-full h-full"
          />
        </div>
      </div>
      <GridCarousel projectTitle={project.title} />
    </div>
  );
}

export default ProjectDetail;
