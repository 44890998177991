import React from "react";
import DOMPurify from 'dompurify';

function Paragraph({ title, content }) {
  return (
    <>
      <div className="w-full flex flex-col px-6 pt-6 md:pt-16 md:px-32 lg:px-80 mb-16">
        <div className="text-[#e8e6e3] font-bold text-5xl mb-4">{title}</div>
        <div className="border-t-4 border-gray-300 mb-6"></div>
        <div className="text-[#c8c3bc]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}/>
      </div>
    </>
  );
}
export default Paragraph;
