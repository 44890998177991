import React from "react";
import { Link } from "react-router-dom";

function Footer({ fixed }) {
  
  return (
    <footer
      className={`bg-black text-white p-4 ${
        fixed ? "md:fixed" : ""
      } text-center md:text-left bottom-0 w-full`}
    >
      <div className="flex flex-col md:flex-row justify-center md:items-center md:justify-between px-6 py-4 md:px-32 lg:px-80">
        <div className="flex-shrink-0 flex justify-center md:justify-left flex-1">
          <img src={`../images/logo.png`} alt="Logo" className="w-24 h-24" />
        </div>
        <div className="mt-4 md:mt-0 md:ml-4 text-left md:text-center flex-1">
          <p>İletişim Adresleri:</p>
          <p>
          info@nowyapi.com
          </p>
        </div>
        <div className="mt-4 md:mt-0 md:ml-4 text-left md:text-right flex-1">
          <p className="text-lg font-bold mb-2">MENU</p>
          <ul>
            <li>
              <Link
                to="/"
                className="text-white hover:text-orange-500 transition duration-300 "
              >
                Anasayfa
              </Link>
            </li>
            <li>
              <Link
                to="/vizyon"
                className="text-white hover:text-orange-500 transition duration-300 "
              >
                Vizyon
              </Link>
            </li>
            <li>
              <Link
                to="/misyon"
                className="text-white hover:text-orange-500 transition duration-300 "
              >
                Misyon
              </Link>
            </li>
            <li>
              <Link
                to="/devam-eden-projeler"
                className="text-white hover:text-orange-500 transition duration-300 "
              >
                Devam Eden Projeler
              </Link>
            </li>
            <li>
              <Link
                to="/tamamlanan-projeler"
                className="text-white hover:text-orange-500 transition duration-300 "
              >
                Tamamlanan Projeler
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="text-white hover:text-orange-500 transition duration-300 "
              >
                İletişim
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
