import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import HomePageText from "../components/HomePageText";
import HomepageCard from "../components/HomepageCard"

function Homepage() {

  useEffect(() => {
    document.title = "Anasayfa - Now Yapi ";
  }, []);

  const HomepageCardTitle = [
    { title: "Vizyon", imageName: "vizyon", redirectURL: "vizyon" },
    { title: "Misyon", imageName: "misyon", redirectURL: "misyon"}
  ];
  
  return (
    <>
      <Navbar />
      <Carousel />
      <HomePageText
        title="GELECEĞİ İNŞA ETMEK"
        content="Geleceği belirleyen temel unsur olan teknolojiye olan bağlılığımızla, inovasyonu sürekli bir şekilde benimseyerek sektörde öncü olmayı amaçlıyoruz. Akıllı bina teknolojilerinden sürdürülebilir enerji çözümlerine kadar geniş bir yelpazede teknolojik gelişmeleri takip ediyor ve uyguluyoruz.

        "
      />
      <HomepageCard HomepageCardTitle={HomepageCardTitle}/>
      <Footer fixed={false} />
    </>
  );
}
export default Homepage;
