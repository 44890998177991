import React, { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Contact() {
  useEffect(() => {
    document.title = "İletişim - Now Yapi";
  }, []);
  return (
    <>
      <Navbar />
      <ContactForm />
      <Footer fixed={true} />
    </>
  );
}
export default Contact;
