import React, { useState, useEffect } from "react";
import ProjectList from "../components/ProjectList";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import projectsData from "../data/projects.json";

function DevamEdenProjeler() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    document.title = "Devam Eden Projeler - Now Yapi";
    setProjects(projectsData);
  }, []);

  const ongoingProjects = projects.filter((project) => project.finishStatus === false);

  return (
    <>
      <Navbar />
      <ProjectList title="Devam Eden Projeler" projects={ongoingProjects} />
      <Footer fixed={true}/>
    </>
  );
}

export default DevamEdenProjeler;
