import React from "react";
import { Link } from "react-router-dom";

function HomepageCard({ HomepageCardTitle }) {
  return (
    <div className="w-full md:flex px-6 pt-6 md:pt-16 md:px-32 lg:px-80 mb-16">
        {HomepageCardTitle.map((category, index) => (
        <div className="md:w-1/2 pr-6" key={index}>
        <div className="text-[#c8c3bc] font-bold text-3xl mb-4 text-center">{category.title}</div>
        <div className="border-t-2 border-gray-300 mb-6"></div>
        <Link to={`/${category.redirectURL}`}>
        <img
          src={`images/projectCategory/${category.imageName}.jpg`}
          alt={category.title}
          className="mb-4 w-full h-[24rem] object-fill transition duration-300 transform hover:brightness-75"
        />
        </Link>
      </div>
      ))}
    </div>
  );
}

export default HomepageCard;
