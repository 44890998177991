import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Grid, Pagination, Navigation } from 'swiper/modules';

import imagesData from "../data/images.json";

function GridCarousel({ projectTitle }) {
  const [projectImages, setProjectImages] = useState([]);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [fullscreenIndex, setFullscreenIndex] = useState(0);

  useEffect(() => {
    const projectData = imagesData.find(data => data.projectTitle === projectTitle);
    setProjectImages(projectData.imagePaths);
  }, [projectTitle]);


  const openFullscreen = (image, index) => {
    setFullscreenImage(image);
    setFullscreenIndex(index);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="px-6 py-6 md:py-16 md:px-32 lg:px-80 z-10">
      <Swiper
        slidesPerView={3}
        grid={{
          rows: 2,
          fill: "row",
        }}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Grid, Pagination, Navigation]}
        className="mySwiper"
      >
        {projectImages.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={`../images/${projectTitle}/${image}`}
              alt={`Slide ${index + 1}`}
              className="object-cover h-[12rem] transition duration-300 transform hover:brightness-75 hover:cursor-pointer"
              onClick={() => openFullscreen(image, index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {fullscreenImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center z-20">
          <div className="absolute top-4 right-4">
            <button
              onClick={closeFullscreen}
              className="text-white text-lg focus:outline-none"
            >
              X
            </button>
          </div>
          <div className="h-[32rem] w-[32rem] md:h-[48rem] md:w-[48rem] text-white">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              navigation={true}
              modules={[Navigation]}
              initialSlide={fullscreenIndex}
            >
              {projectImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={`../images/${projectTitle}/${image}`}
                    alt={`Slide ${index + 1}`}
                    className="h-full object-cover"
                  />
                </SwiperSlide>
              ))}
              <div className="swiper-button-next-modal"></div>
              <div className="swiper-button-prev-modal"></div>
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
}

export default GridCarousel;
