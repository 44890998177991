import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import ProjectDetail from "../components/ProjectDetail";
import Footer from "../components/Footer";

import projectsData from "../data/projects.json";

function ProjectPage(){
  const { projectId } = useParams();
  const project = projectsData.find((p) => String(p.id) === projectId);
  

  if (!project) {
    return <div>Proje bulunamadı</div>;
  }

  return (
    <>
      <Navbar />
      <ProjectDetail project={project} />
      <Footer fixed={false}/>
    </>
  );
};

export default ProjectPage;
