import React, { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <nav className="bg-black text-white p-4 z-20">
      <div className="px-4 md:px-32 lg:px-80">
        {/* Telefon Boyutunda Logo ve Menü Butonu Ayarlaması */}
        <div className="flex items-center justify-between md:hidden">
          <Link to="/" className="flex">
            <img src={`../images/logo.png`} alt="Logo" className="h-8 w-8" />
            <span className="ml-2 text-lg font-bold">NOW YAPI</span>
          </Link>
          <div>
            <button
              onClick={toggleMobileMenu}
              className="text-white hover:text-orange-500 focus:outline-none"
            >
              <svg
                className={`h-6 w-6 ${
                  isMobileMenuOpen ? "text-orange-500" : "text-white"
                }`}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>

        <div className="hidden md:flex md:space-x-8">
          <Link to="/" className="flex w-1/6">
            <img src={`../images/logo.png`} alt="Logo" className="h-8 w-8" />
            <span className="ml-2 text-lg font-bold">NOW YAPI</span>
          </Link>
          <div className="md:w-5/6 md:flex md:justify-between md:items-center border-y-2 border-gray-600 px-4">
            <Link
              to="/"
              className="text-white hover:text-orange-500 transition duration-300 font-bold"
            >
              Anasayfa
            </Link>
            <Link
              to="/vizyon"
              className="text-white hover:text-orange-500 transition duration-300 font-bold"
            >
              Vizyon
            </Link>
            <Link
              to="/misyon"
              className="text-white hover:text-orange-500 transition duration-300 font-bold"
            >
              Misyon
            </Link>
            <div
              className="relative inline-block text-left group"
              onMouseEnter={openDropdown}
              onMouseLeave={closeDropdown}
            >
              <button className="text-white hover:text-orange-500 flex items-center focus:outline-none transition duration-300 font-bold">
                Projeler
              </button>
              {isDropdownOpen && (
                <div
                  className="origin-top-left absolute left-0 w-40 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20"
                  onMouseEnter={openDropdown}
                  onMouseLeave={closeDropdown}
                >
                  <div>
                    <Link
                      to="/devam-eden-projeler"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-orange-500 font-bold"
                    >
                      Devam Eden Projeler
                    </Link>
                    <Link
                      to="/tamamlanan-projeler"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-orange-500 font-bold"
                    >
                      Tamamlanan Projeler
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <Link
              to="/contact"
              className="text-white hover:text-orange-500 transition duration-300 font-bold"
            >
              İletişim
            </Link>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="lg:hidden px-4">
          <Link
            to="/"
            className="block text-white hover:text-orange-500 transition duration-300 font-bold mt-2"
          >
            Anasayfa
          </Link>
          <Link
            to="/vizyon"
            className="block text-white hover:text-orange-500 transition duration-300 font-bold"
          >
            Vizyon
          </Link>
          <Link
            to="/misyon"
            className="block text-white hover:text-orange-500 transition duration-300 font-bold"
          >
            Misyon
          </Link>
          <Link
            to="/devam-eden-projeler"
            className="block text-white hover:text-orange-500 transition duration-300 font-bold"
          >
            Devam Eden Projeler
          </Link>
          <Link
            to="/tamamlanan-projeler"
            className="block text-white hover:text-orange-500 transition duration-300 font-bold"
          >
            Tamamlanan Projeler
          </Link>
          <Link
            to="/contact"
            className="block text-white hover:text-orange-500 transition duration-300 font-bold"
          >
            İletişim
          </Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
