import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Homepage from "../pages/Homepage";
import Vizyon from "../pages/Vizyon"
import Misyon from "../pages/Misyon";
import Contact from "../pages/Contact";
import DevamEdenProjeler from "../pages/DevamEdenProjeler";
import TamamlananProjeler from "../pages/TamamlananProjeler";
import ProjectPage from "../pages/ProjectPage"

function index() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/homepage" element={<Homepage />} />
        <Route path="/vizyon" element={<Vizyon />} />
        <Route path="/misyon" element={<Misyon />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/devam-eden-projeler" element={<DevamEdenProjeler />} />
        <Route path="/tamamlanan-projeler" element={<TamamlananProjeler />} />
        <Route path="/project/:projectId" element={<ProjectPage />}/>
      </Routes>
    </Router>
  );
}

export default index;
