import React, { useState, useEffect } from "react";
import ProjectList from "../components/ProjectList";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import projectsData from "../data/projects.json";
function TamamlananProjeler() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    document.title = "Tamamlanan Projeler - Now Yapi";
    setProjects(projectsData);
  }, []);

  

  const finishedProject = projects.filter((project) => project.finishStatus === true);

  return (
    <>
      <Navbar />
      <ProjectList title="Tamamlanan Projeler" projects={finishedProject} />
      <Footer fixed={false}/>
    </>
  );
}
export default TamamlananProjeler;
