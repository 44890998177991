import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Paragraph from "../components/Paragraph";
import Footer from "../components/Footer";

function About() {
  useEffect(() => {
    document.title = "Hakkımızda - Now Yapi";
  }, []);
  return (
    <>
      <Navbar />
      <Paragraph
        title="Now Yapı"
        content="<p class='font-bold'>İnşa Ederken Değişim Yaratmak</p>
        <p>Now Yapı İnşaat Şirketi olarak, misyonumuz, inşa ettiğimiz her yapıda sadece duvarları değil, aynı zamanda toplulukları, hayalleri ve gelecekleri de inşa etmektir. İnşaat sektöründe öncü olmak, müşterilerimizin beklentilerini aşmak ve topluma değer katmak için bir dizi temel prensibi benimsiyoruz.</p>
        <br/>
        <p class='font-bold'>Kalite ve Güvenilirlik:</p>
        <p>Her bir projemizde kalite standartlarına sadık kalarak, müşterilerimize uzun ömürlü ve güvenli binalar sunmayı taahhüt ediyoruz. Kalite kontrol süreçlerimiz, sektördeki en iyi uygulamaları benimsememize ve mükemmeliyeti hedeflememize olanak tanır.</p>
        <br/>
        <p class='font-bold'>Müşteri Odaklılık:</p>
        <p>Müşterilerimizin ihtiyaçlarını anlamak ve onlara en iyi hizmeti sunmak, misyonumuzun temelidir. Her aşamada müşteri memnuniyetini sağlamak için proaktif bir yaklaşım benimser, iletişimde şeffaflığı ve dürüstlüğü esas alırız.</p>
        <br/>
        <p class='font-bold'>Çevre Dostu İnşaat:</p>
        <p>Doğayı koruma ve sürdürülebilir bir gelecek için sorumluluk almak, misyonumuzun bir parçasıdır. Enerji verimliliği, geri dönüşümlü malzemelerin kullanımı ve çevre dostu uygulamalarla çevresel etkimizi minimize etmeyi amaçlıyoruz.</p>
        <br/>
        <p class='font-bold'>Teknoloji ve İnovasyon:</p>
        <p>Teknolojinin gücünü kullanarak, inşaat sektöründe yenilikçi çözümler üretmek hedefimizdir. Akıllı bina sistemleri, dijital tasarım teknolojileri ve yapay zeka gibi ileri teknolojileri benimseyerek projelerimizi daha etkili ve verimli hale getiriyoruz.</p>
        <br/>
        <p class='font-bold'>Toplumsal Katılım:</p>
        <p>Topluluklarımıza değer katmak ve onlarla güçlü bir bağ kurmak, misyonumuzun önemli bir unsurudur. Eğitim projelerine destek vermek, yerel istihdamı teşvik etmek ve sosyal sorumluluk projelerine aktif olarak katılmak, toplumsal kalkınmaya olan taahhüdümüzü yansıtır.</p>
        <br/>
        <p class='font-bold'>Personel Gelişimi ve İyi İş Yeri Kültürü:</p>
        <p>Personelimizin sürekli gelişimini destekleyerek, ekip üyelerimizin potansiyellerini maksimize etmeyi amaçlıyoruz. Adil bir iş yeri kültürü oluşturarak, çalışanlarımızın işlerine duydukları tutkuyu ve bağlılığı artırmak en öncelikli hedeflerimizden biridir.</p>
        <br/>
        <p>Now Yapı İnşaat Şirketi olarak, inşaat sektöründe sürdürülebilir bir gelecek inşa etme misyonu ile hareket ediyoruz. Topluma ve müşterilere değer katarak, etik değerlere bağlılıkla, güçlü ve dayanıklı binalar inşa etmeyi sürdüreceğiz</p>"
      />
      <Footer fixed={false} />
    </>
  );
}
export default About;
