import React, { useState } from "react";

function ContactForm() {
  const [formData, setFormData] = useState({
    subject: "",
    name: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:info@nowyapi.com?subject=${encodeURIComponent(formData.subject)}&body=İsim-Soyisim: ${encodeURIComponent(formData.name)}%0AMesaj: ${encodeURIComponent(formData.message)}`;

    window.open(mailtoLink, "_blank");
  };

  return (
    <div className="px-6 pt-6 md:pt-16 md:px-32 lg:px-[32rem] mb-16">
      <form onSubmit={handleSubmit} className="my-10 mx-2 md:mx-auto">
        <div className="mb-4">
          <label
            htmlFor="subject"
            className="block text-sm font-bold text-[#c8c3bc]"
          >
            Konu
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-bold text-[#c8c3bc]"
          >
            Adınız (gerekli)
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-sm font-bold text-[#c8c3bc]"
          >
            İletiniz
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <button
          type="submit"
          className="bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300"
        >
          Gönder
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
